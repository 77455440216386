(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.LanguageButtonSkin'] = {
  "react": [
    [
      "div",
      "icon",
      [],
      {
        "aria-hidden": true
      }
    ],
    [
      "div",
      "label",
      [],
      {}
    ],
    [
      "div",
      "spacer",
      [],
      {
        "aria-hidden": true
      }
    ],
    [
      "div",
      "arrow-icon",
      [],
      {
        "aria-hidden": true
      },
      [
        "svg",
        null,
        [],
        {
          "data-name": "Layer 1",
          "xmlns": "http://www.w3.org/2000/svg",
          "viewBox": "0 0 9.2828 4.89817"
        },
        [
          "defs",
          null,
          [],
          {}
        ],
        [
          "title",
          null,
          [],
          {}
        ],
        [
          "path",
          null,
          [
            "_cls-1"
          ],
          {
            "d": "M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"
          }
        ]
      ]
    ]
  ],
  "params": {
    "itemFont": "FONT",
    "itemTextColor": "TEXT_COLOR",
    "backgroundColor": "BG_COLOR_ALPHA",
    "itemTextColorHover": "TEXT_COLOR",
    "backgroundColorHover": "BG_COLOR_ALPHA",
    "itemTextColorActive": "TEXT_COLOR",
    "backgroundColorActive": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "itemFont": "font_0",
    "itemTextColor": "color_1",
    "backgroundColor": "color_4",
    "itemTextColorHover": "color_1",
    "backgroundColorHover": "color_4",
    "itemTextColorActive": "color_1",
    "backgroundColorActive": "color_4"
  },
  "css": {
    "%": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;cursor:pointer;line-height:0;[itemFont]  color:[itemTextColor];background-color:[backgroundColor];",
    "% path": "fill:[itemTextColor];",
    "%:hover,%[data-preview=\"hover\"],%:focus": "color:[itemTextColorHover];background-color:[backgroundColorHover];position:relative;overflow:hidden;",
    "%:hover path,%[data-preview=\"hover\"] path,%:focus path": "fill:[itemTextColorHover];",
    "%_selected:hover,%_selected[data-preview=\"hover\"],%_selected:focus": "color:[itemTextColorActive];background-color:[backgroundColorActive];",
    "%_selected:hover path,%_selected[data-preview=\"hover\"] path,%_selected:focus path": "fill:[itemTextColorActive];",
    "%:active,%[data-preview=\"active\"],%_selected": "color:[itemTextColorActive];background-color:[backgroundColorActive];",
    "%:active path,%[data-preview=\"active\"] path,%_selected path": "fill:[itemTextColorActive];",
    "%_left": "-webkit-box-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;",
    "%_left %spacer": "-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;",
    "%_left %label%_has-text": "margin:0 0 0 14px;",
    "%_left%_horizontal %label%_has-text": "margin:0 14px 0 14px;",
    "%_left %icon%_has-icon": "margin:0 -6px 0 14px;",
    "%_left %arrow-icon": "margin:0 14px 0 20px;",
    "%_right": "-webkit-box-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;",
    "%_right %spacer": "-webkit-box-ordinal-group:3;-webkit-order:2;order:2;-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;",
    "%_right %label%_has-text": "-webkit-box-ordinal-group:4;-webkit-order:3;order:3;margin:0 14px 0 0;",
    "%_right%_horizontal %label%_has-text": "margin:0 14px 0 14px;",
    "%_right %icon%_has-icon": "-webkit-box-ordinal-group:5;-webkit-order:4;order:4;margin:0 14px 0 -6px;",
    "%_right %arrow-icon": "-webkit-box-ordinal-group:2;-webkit-order:1;order:1;margin:0 20px 0 14px;",
    "%_center": "padding:0 40px;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;",
    "%_center%_horizontal": "padding:0;",
    "%_center%_horizontal %icon%_has-icon": "margin:0 4px 0 4px;",
    "%_center%_horizontal %label%_has-text": "margin:0 4px 0 4px;",
    "%_center %label%_has-text": "margin:0 4px;",
    "%_center %icon%_has-icon": "margin:0 4px;",
    "%_center %arrow-icon": "position:absolute;right:0;margin:0 14px 0 20px;",
    "%_hidden %arrow-icon": "display:none;",
    "%_hidden %spacer": "display:none;",
    "%_mobile": "padding:0;",
    "%icon": "box-sizing:border-box;",
    "%icon %_iconImg": "display:block;",
    "%arrow-icon": "line-height:0;",
    "%arrow-icon svg": "min-width:12px;width:12px;height:auto;"
  }
}
 skins['wysiwyg.viewer.skins.LanguageSelectorSkin'] = {
  "react": [
    [
      "div",
      "beakerFitContentTestDiv",
      [],
      {}
    ]
  ],
  "exports": {
    "languageButton": {
      "skin": "wysiwyg.viewer.skins.LanguageButtonSkin"
    }
  },
  "params": {
    "borderColor": "BORDER_COLOR_ALPHA",
    "borderWidth": "BORDER_SIZE",
    "borderRadius": "BORDER_RADIUS",
    "boxShadow": "BOX_SHADOW",
    "separatorColor": "COLOR_ALPHA",
    "backgroundColor": "BG_COLOR_ALPHA",
    "borderRadiusValue": "SIZE"
  },
  "paramsDefaults": {
    "borderColor": "#202020",
    "borderWidth": "1px",
    "borderRadius": "5px",
    "boxShadow": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "separatorColor": "#fefefe",
    "backgroundColor": "color_4",
    "borderRadiusValue": [
      "borderRadius"
    ]
  },
  "css": {
    "%": "display:table;",
    "%_disabled": "opacity:0.38;",
    "%_disabled *,%_disabled:active": "pointer-events:none;",
    "%_table-wrapper": "display:table-cell;height:100%;",
    "%_horizontal": "display:-webkit-box;display:-webkit-flex;display:flex;width:100%;height:100%;box-sizing:border-box;border-style:solid;border-color:[borderColor];border-width:[borderWidth];[borderRadius]  [boxShadow]",
    "%_horizontal > *": "-webkit-box-flex:1;-webkit-flex:auto;flex:auto;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_horizontal %languageButton + %languageButton": "border-left-width:1px;border-left-style:solid;border-left-color:[separatorColor];",
    "%_horizontal %languageButton:first-child,%_horizontal %languageButton:last-child": "[borderRadius]",
    "%_horizontal %languageButton:first-child": "border-top-right-radius:0;border-bottom-right-radius:0;",
    "%_horizontal %languageButton:last-child": "border-top-left-radius:0;border-bottom-left-radius:0;",
    "%_dropdown": "width:100%;position:relative;white-space:nowrap;display:block;",
    "%_dropdown %_currentLanguage": "cursor:pointer;position:relative;height:100%;width:100%;box-sizing:border-box;border-style:solid;border-color:[borderColor];border-width:[borderWidth];[borderRadius]    [boxShadow]",
    "%_dropdown %_dropdownWrapper": "z-index:99;position:absolute;[boxShadow]    box-sizing:border-box;background-color:[backgroundColor];border-style:solid;border-color:[borderColor];border-width:[borderWidth];[borderRadius]",
    "%_dropdown %_dropdownWrapper %languageButton + %languageButton": "border-top-width:1px;border-top-style:solid;border-top-color:[separatorColor];",
    "%_dropdown %_dropdownWrapper %languageButton:first-child,%_dropdown %_dropdownWrapper %languageButton:last-child": "border-radius:calc([borderRadiusValue] - [borderWidth]);",
    "%_dropdown %_dropdownWrapper %languageButton:first-child": "border-bottom-left-radius:0;border-bottom-right-radius:0;",
    "%_dropdown %_dropdownWrapper %languageButton:last-child": "border-top-left-radius:0;border-top-right-radius:0;",
    "%_hidden": "display:none !important;",
    "%_dropdownButton%_arrowButton %languageButtonarrow-icon": "-webkit-transform:scaleY(-1);transform:scaleY(-1);",
    "%_currentLanguage %languageButtonlabel": "overflow:hidden;text-overflow:ellipsis;",
    "%_open": "z-index:47;",
    "%_mobileLanguageSelector": "position:relative;z-index:auto;",
    "%_mobileLanguageSelector %_currentLanguage": "cursor:pointer;position:relative;height:100%;width:100%;box-sizing:border-box;border-style:solid;border-color:[borderColor];border-width:[borderWidth];[borderRadius]    [boxShadow]",
    "%_mobileLanguageSelector:not(%_newMobileLanguageSelector) %languageButton": "padding:0;",
    "%_mobileLanguageSelector:not(%_newMobileLanguageSelector) %languageButtonicon": "margin:0 auto !important;",
    "%_mobileLanguageSelector select": "opacity:0;position:absolute;top:0;left:0;width:100%;height:100%;font-size:25px;"
  }
}

        return skins;
    }));