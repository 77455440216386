define([

], function (

) {
    'use strict';

    return {
        HIDDEN: 'hidden',
        PLACEHOLDER: 'placeholder',
        VISIBLE: 'visible'
    };
});
