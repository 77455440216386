// this.props.compProp.iconSize = undefined means old component, not migrated

define([
    'santa-components',
    'coreUtils',
    'prop-types',
    'coreMultilingual',
    'languageSelector/languageButton/arrowIconMode',
    'componentsCore'
], function (
    santaComponents,
    coreUtils,
    PropTypes,
    coreMultilingual,
    ArrowIconMode,
    componentsCore
) {
    'use strict';

    const WIX_USER_LANG_CODE = 'en';
    const ItemFormat = {
        SHORT_NAME: 'shortName',
        FULL_NAME: 'fullName',
        FULL_LOCALIZED_NAME: 'fullLocalizedName',
        ICON_ONLY: 'iconOnly'
    };
    const flagIconTypes = {
        rounded: 'corner',
        circle: 'round',
        square: 'square'
    };

    const languageButton = {
        displayName: 'LanguageButton',
        mixins: [componentsCore.mixins.skinBasedComp],

        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compTheme: santaComponents.santaTypesDefinitions.Component.theme.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
            linguistFlags: PropTypes.string,
            onClick: PropTypes.func,
            arrowIconMode: PropTypes.string.isRequired,
            isMobile: PropTypes.bool,
            iconType: PropTypes.string.isRequired,
            iconSize: PropTypes.oneOfType([
                PropTypes.number
            ]).isRequired,
            isHorizontal: PropTypes.bool
        },

        getTranslation(translationKey) {
            return coreUtils.translationsLoader.getTranslation('MULTILINGUAL', WIX_USER_LANG_CODE, translationKey);
        },

        getIconUrl() {
            if (this.props.isExperimentOpen('sv_multilingualFlagsPng')) {
                const normalSize = `${this.props.linguistFlags}/assets/flags/${flagIconTypes[this.props.iconType]}/${this.props.compData.language.countryCode}.png`;
                const doubleSize = `${this.props.linguistFlags}/assets/flags/${flagIconTypes[this.props.iconType]}/${this.props.compData.language.countryCode}_2x.png`;
                return {
                    srcset: `${normalSize}, ${doubleSize} 2x`,
                    src: normalSize
                };
            }

            return {
                src: coreMultilingual.utils.getFlagIconUrl(this.props.linguistFlags, this.props.iconType, this.props.compData.language.countryCode)
            };
        },

        createLabel() {
            const {language} = this.props.compData;
            const itemFormat = this.props.compProp.itemFormat;
            if (itemFormat === ItemFormat.SHORT_NAME) {
                return language.languageCode.toUpperCase();
            } else if (itemFormat === ItemFormat.FULL_LOCALIZED_NAME) {
                return language.localizedName;
            } else if (itemFormat === ItemFormat.FULL_NAME) {
                return language.name;
            }
        },

        createIcon() {
            const imgElement = [santaComponents.utils.createReactElement('img', {
                ...this.getIconUrl(),
                style: {height: `${this.props.iconSize}px`},
                className: this.classSet({
                    iconImg: true
                })
            })];
            return {
                className: this.classSet({'has-icon': true}),
                children: imgElement
            };
        },

        onButtonClick() {
            if (this.props.onClick) {
                this.props.onClick(this.props.compData.language.languageCode);
            }
        },

        onButtonKeyDown(event) {
            if (this.props.onKeyDown) {
                this.props.onKeyDown(event, this.props.compData.language.languageCode);
            }
        },

        getClassSet() {
            return {
                [this.props.compProp.alignTabs]: true,
                [this.props.arrowIconMode]: true,
                horizontal: this.props.isHorizontal,
                selected: this.props.selected,
                mobile: this.props.isMobile
            };
        },

        getSkinProperties() {
            return {
                '': {
                    className: this.classSet(this.getClassSet()),
                    onClick: this.onButtonClick,
                    onKeyDown: this.onButtonKeyDown,
                    role: this.props.role,
                    'aria-current': this.props.selected
                },
                'label': this.props.isMobile && !this.props.compProp.iconSize ? null : {
                    className: this.classSet({'has-text': this.props.compProp.itemFormat !== ItemFormat.ICON_ONLY}),
                    children: this.createLabel(),
                    'aria-label': this.props.compData.language.name,
                    tabIndex: this.props.tabIndex,
                    'role': 'link'
                },
                'icon': this.props.iconType === 'none' ? null : this.createIcon(),
                'arrow-icon': {
                    hidden: this.props.arrowIconMode === ArrowIconMode.HIDDEN
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.LanguageButton', languageButton);
    return languageButton;
});
