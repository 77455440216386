// this.props.compProp.iconSize = undefined means old component, not migrated

define([
    'santa-components',
    'lodash',
    'componentsCore',
    'skins',
    'textCommon',
    'languageSelector/skins/skins.json',
    'languageSelector/languageButton/arrowIconMode',
    'languageSelector/bi/events',
    'languageSelector/languageButton/languageButton'
], function (santaComponents, _, componentsCore, skinsPackage, textCommon, skinsJson, ArrowIconMode, biEvents) {
    'use strict';

    const buttonVerticalPadding = 20;
    const squareAspect = 13 / 8;
    const paddings = {
        icon: 6,
        arrow: 34 + 12, // paddings + width
        text: 14
    };

    const KEY_RETURN = 13;
    const KEY_SPACE = 32;
    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_ESCAPE = 27;

    const focusRingClass = 'focus-ring';

    const languageSelector = {
        displayName: 'LanguageSelector',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin, textCommon.textScaleMixin],

        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compTheme: santaComponents.santaTypesDefinitions.Component.theme.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            isEnabled: santaComponents.santaTypesDefinitions.RendererModel.multilingual.isEnabled,
            languages: santaComponents.santaTypesDefinitions.RendererModel.multilingual.languages,
            originalLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.originalLanguage,
            currentLanguage: santaComponents.santaTypesDefinitions.RendererModel.multilingual.currentLanguage,
            setCurrentLanguageCode: santaComponents.santaTypesDefinitions.RendererModel.multilingual.setCurrentLanguageCode,
            scriptsLocationMap: santaComponents.santaTypesDefinitions.ServiceTopology.scriptsLocationMap,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            pageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId.isRequired,
            reportBI: santaComponents.santaTypesDefinitions.reportBI.isRequired,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired
        },

        componentDidUpdate(prevProps, prevState) {
            const {keyboardNavigationIndex} = this.state;

            if (!_.isNull(keyboardNavigationIndex) && keyboardNavigationIndex !== prevState.keyboardNavigationIndex) {
                this.focusButton(keyboardNavigationIndex);
            }

            this.useEffect(() => {
                const {languages, currentLanguage, originalLanguage: {languageCode: originalLanguageCode}} = this.props;
                const translationLanguages = languages.filter(({languageCode}) => languageCode !== originalLanguageCode);
                if (translationLanguages.length) {
                    const allLanguagesOrCurrentInactive = this.isLanguageInactive(currentLanguage) || translationLanguages.every(this.isLanguageInactive);
                    const isDisabled = this.isEditorPreview() && allLanguagesOrCurrentInactive;
                    this.refs[''].classList.toggle(this.classSet({'disabled': true}), isDisabled);
                }
            }, ['componentViewMode', 'languages', 'currentLanguage'], prevProps);
        },

        isLanguageInactive(language) {
            return language.status && language.status.toLowerCase() === 'inactive';
        },

        isEditorPreview() {
            return this.props.componentViewMode === 'preview';
        },

        useEffect(callback, dependencies, prevProps) {
            const watchedPropChanged = dependencies.some(dependency => prevProps[dependency] !== this.props[dependency]);
            if (watchedPropChanged) {
                callback();
            }
        },

        componentDidMount() {
            this.props.reportBI({
                evid: 427
            }, {
                language_rendered: this.props.currentLanguage.languageCode,
                page_id: this.props.pageId
            });
        },

        getMinContentHeight() {
            const {itemFont} = this.props.compTheme.style.properties;
            const iconSize = this.getIconSize();
            const matchFontStyle = fontData => _.head(itemFont.match(fontData) || [0]);
            const fontSize = parseInt(matchFontStyle(/\d+px/), 10);
            const lineHeight = parseFloat(matchFontStyle(/\d+\.?\d*em/));
            const fontHeight = Math.ceil(fontSize * lineHeight);

            const iconHeight = this.getIconType() === 'none' ?
                0 : _.parseInt(iconSize, 10);
            return Math.max(fontHeight, iconHeight) + buttonVerticalPadding;
        },

        getMinContentWidth() {
            const iconSize = this.getIconSize();
            const iconType = this.getIconType();
            const hasText = this.props.compProp.itemFormat !== 'iconOnly';
            const hasIcon = iconType !== 'none';

            const iconMultiplier = iconType === 'circle' ? 1 : squareAspect;
            const iconWidth = _.parseInt(iconSize, 10) * iconMultiplier;

            const iconOuterWidth = hasIcon ? iconWidth + paddings.icon : 0;
            const textPadding = hasText ? paddings.text : 0;

            return iconOuterWidth + textPadding + paddings.arrow;
        },

        orderLanguages(language) {
            let languagesOrder = this.props.compProp.languagesOrder;
            if (languagesOrder.length === 0) {
                languagesOrder = [this.props.originalLanguage.languageCode];
            }

            const index = _.indexOf(languagesOrder, language.languageCode);
            if (index === -1) {
                return Infinity;
            }
            return index;
        },

        createButton(language, props) {
            return this.createChildComponent({language},
                'wysiwyg.viewer.components.LanguageButton',
                'languageButton',
                _.assign(
                    {
                        iconType: this.getIconType(),
                        iconSize: this.getIconSize(),
                        linguistFlags: this.props.scriptsLocationMap['linguist-flags'],
                        id: `${this.props.id + language.languageCode}Button`
                    },
                    props
                )
            );
        },

        getOrderedLanguages() {
            let languages = _.sortBy(this.props.languages, this.orderLanguages);
            if (this.isEditorPreview()) {
                languages = languages.filter(({status}) => status.toLowerCase() === 'active');
            }
            return languages;
        },

        getLanguageList() {
            const languages = this.getOrderedLanguages();
            const currentLanguage = this.props.currentLanguage;
            const filteredLanguages = _.reject(languages, this.props.currentLanguage);
            return [currentLanguage, ...filteredLanguages];
        },

        getAriaSelectorLabel() {
            return this.props.getTranslatedAriaLabel('ariaLabels', 'LanguageSelector_AriaLabel_TopLevel', 'Language Selector - {{language}} selected').replace('{{language}}', this.props.currentLanguage.name);
        },

        focusButton(index) {
            return this.refs.dropdownWrapper.querySelectorAll('[role="menuitem"]')[index].focus();
        },

        createDropdownButtons(buttonHeight, revert) {
            let languages = this.getLanguageList();

            let arrowIndex = 0;
            if (revert) {
                languages = languages.reverse();
                arrowIndex = languages.length - 1;
            }
            return _.map(languages, (language, index) => {
                const globalClasses = index === this.state.keyboardNavigationIndex ? focusRingClass : '';
                const componentClasses = this.classSet({
                    'dropdownButton': true,
                    'arrowButton': index === arrowIndex
                });

                return this.createButton(
                    language,
                    {
                        key: language.locale,
                        tabIndex: index === arrowIndex ? null : -1,
                        role: 'menuitem',
                        style: {height: buttonHeight},
                        arrowIconMode: index === arrowIndex ? ArrowIconMode.VISIBLE : ArrowIconMode.HIDDEN,
                        className: `${globalClasses} ${componentClasses}`,
                        selected: index === arrowIndex,
                        iconType: this.getIconType(),
                        onClick: () => {
                            this.setCurrentLanguageCode(language.languageCode);
                            this.closeDropdown();
                        }
                    }
                );
            });
        },

        getInitialState() {
            return {
                isDropdownOpen: false,
                isDropdownReverted: false,
                keyboardNavigationIndex: null
            };
        },

        toggleDropDown() {
            if (!this.state.isDropdownOpen) {
                this.openDropdown();
            } else {
                this.closeDropdown();
            }
        },

        openDropdown(isOpenedByKeyboard) {
            const languages = this.getLanguageList();

            const isDropdownReverted = this.shouldDropdownOpenInReverse();
            const keyboardFirstOptionIndex = isDropdownReverted ? 0 : 1;

            this.setState({
                isDropdownOpen: true,
                isDropdownReverted,
                keyboardNavigationIndex: isOpenedByKeyboard && languages.length > 1 ? keyboardFirstOptionIndex : null
            });
        },

        closeDropdown(isClosedByKeyboard) {
            this.setState({
                isDropdownOpen: false,
                keyboardNavigationIndex: null,
                isKeyboardFocused: isClosedByKeyboard
            });
        },

        shouldDropdownOpenInReverse() {
            if (!window) {
                return false;
            }
            const windowHeight = window.innerHeight;
            const dimensions = this.refs[''].getBoundingClientRect();
            const topSpace = dimensions.top + dimensions.height;
            const bottomSpace = windowHeight - dimensions.top;
            const numberOfLanguages = this.getOrderedLanguages().length;
            const borderWidthFromTwoSides = parseInt(this.props.compTheme.style.properties.borderWidth, 10) * 2;
            const dropdownButtonHeight = this.props.style.height - borderWidthFromTwoSides;
            const openedDropdownHeight = dropdownButtonHeight * numberOfLanguages + borderWidthFromTwoSides; // eslint-disable-line no-mixed-operators
            const canOpenDownwards = bottomSpace >= openedDropdownHeight;
            const canOpenUpwards = topSpace >= openedDropdownHeight;
            return canOpenUpwards && !canOpenDownwards;
        },

        getHorizontalComponent() {
            const textStyle = this.getFontSize('itemFont', this.props.scale);
            const buttons = _.map(this.getOrderedLanguages(),
                function (language) {
                    return this.createButton(
                        language,
                        {
                            key: language.locale,
                            arrowIconMode: ArrowIconMode.HIDDEN,
                            selected: language.locale === this.props.currentLanguage.locale,
                            onClick: this.setCurrentLanguageCode,
                            iconType: this.getIconType(),
                            isHorizontal: true,
                            tabIndex: language.locale === this.props.currentLanguage.locale ? null : 0,
                            onKeyDown: this.handleHorizontalButtonKeyDown,
                            style: textStyle
                        }
                    );
                }.bind(this)
            );

            return {
                '': {
                    children: santaComponents.utils.createReactElement('div', {
                        className: this.classSet({'table-wrapper': true}),
                        children: santaComponents.utils.createReactElement('div', {
                            className: this.classSet({horizontal: true}),
                            'aria-label': this.getAriaSelectorLabel(),
                            'role': 'navigation',
                            children: buttons
                        })
                    })
                }
            };
        },

        handleHorizontalButtonKeyDown(event, languageCode) {
            if (event.keyCode === KEY_RETURN) {
                this.setCurrentLanguageCode(languageCode);
            }
        },

        setCurrentLanguageCode(languageCode) {
            if (this.props.isPreviewMode) {
                this.props.reportBI(biEvents.LANGUAGE_CHOSEN_UOU_MENU, {
                    page_id: this.props.pageId,
                    main_language: this.props.originalLanguage.languageCode,
                    current_language: this.props.currentLanguage.languageCode,
                    chosen_language: languageCode,
                    languages: this.props.languages
                        .map(language => language.languageCode)
                        .join()
                });
            }
            this.props.setCurrentLanguageCode(languageCode);
        },

        getNextKeyboardNavigationIndex(keyCode) {
            const {isDropdownReverted, keyboardNavigationIndex: currentIndex} = this.state;

            const languages = this.getLanguageList();
            const navLength = languages.length - 1;

            const firstOptionIndex = isDropdownReverted ? 0 : 1;
            const lastOptionIndex = isDropdownReverted ? navLength - 1 : navLength;

            const nextIndex = keyCode === KEY_UP ? currentIndex - 1 : currentIndex + 1;
            return _.clamp(nextIndex, firstOptionIndex, lastOptionIndex);
        },

        getDropdownButton() {
            return this.refs[''];
        },

        focusDropdownButton() {
            const dropdownButton = this.getDropdownButton();
            dropdownButton.focus();
        },

        handleBlur(event) {
            if (event.target === this.getDropdownButton()) {
                this.setState({isKeyboardFocused: false});
            }

            _.defer(() => {
                const {isDropdownOpen} = this.state;
                const {dropdownWrapper} = this.refs;
                const {activeElement} = window.document;

                if (isDropdownOpen && dropdownWrapper && !dropdownWrapper.contains(activeElement)) {
                    this.closeDropdown();
                }
            });
        },

        showDropdownIfClosedOtherwise(action) {
            if (!this.state.isDropdownOpen) {
                this.openDropdown(true);
            } else {
                action();
            }
        },

        getDropdownComponent() {
            const isDropdownOpen = this.props.componentPreviewState || this.state.isDropdownOpen;
            const borderWidthFromTwoSides = parseInt(this.props.compTheme.style.properties.borderWidth, 10) * 2;
            const dropdownButtonHeight = this.props.style.height - borderWidthFromTwoSides;
            const minHeight = this.getMinContentHeight() + borderWidthFromTwoSides;
            const minWidth = this.getMinContentWidth() + borderWidthFromTwoSides;

            const dropdownRevert = this.state.isDropdownReverted;

            const dropdownStyle = {
                minWidth: this.props.style.width,
                left: 0,
                top: dropdownRevert ? 'auto' : 0,
                bottom: !dropdownRevert ? 'auto' : 0
            };

            const globalClasses = this.state.isKeyboardFocused ? focusRingClass : '';
            const componentClasses = this.classSet({'dropdown': true, 'open': isDropdownOpen, 'revert': dropdownRevert});

            return {
                '': {
                    tabIndex: 0,
                    'aria-haspopup': true,
                    'aria-expanded': isDropdownOpen,
                    role: 'listbox',
                    'aria-label': this.getAriaSelectorLabel(),
                    className: `${globalClasses} ${componentClasses}`,
                    style: {minHeight, minWidth},
                    onBlur: this.handleBlur,
                    onKeyDown: event => {
                        switch (true) {
                            case event.keyCode === KEY_RETURN:
                            case event.keyCode === KEY_SPACE:
                                this.showDropdownIfClosedOtherwise(() => {
                                    const languages = this.getLanguageList();
                                    if (this.state.isDropdownReverted) {
                                        languages.reverse();
                                    }
                                    const currentIndex = this.state.keyboardNavigationIndex;
                                    this.setCurrentLanguageCode(languages[currentIndex].languageCode);
                                    this.closeDropdown();
                                });
                                event.preventDefault();
                                break;

                            case event.keyCode === KEY_UP:
                                this.setState({
                                    keyboardNavigationIndex: this.getNextKeyboardNavigationIndex(event.keyCode)
                                });
                                event.preventDefault();
                                break;

                            case event.keyCode === KEY_DOWN:
                                this.showDropdownIfClosedOtherwise(() => {
                                    this.setState({
                                        keyboardNavigationIndex: this.getNextKeyboardNavigationIndex(event.keyCode)
                                    });
                                });
                                event.preventDefault();
                                break;

                            case event.keyCode === KEY_ESCAPE:
                                this.closeDropdown(true);
                                this.focusDropdownButton();
                                break;
                        }
                    },
                    children: [
                        this.createButton(
                            this.props.currentLanguage,
                            {
                                className: this.classSet({'currentLanguage': true, 'hidden': isDropdownOpen}),
                                arrowIconMode: this.props.compProp.hasArrow || !this.props.compProp.iconSize ? ArrowIconMode.VISIBLE : ArrowIconMode.HIDDEN,
                                onClick: this.toggleDropDown,
                                ref: 'currentLanguage'
                            }
                        ),
                        santaComponents.utils.createReactElement('div', {
                            key: 'dropdownWrapper',
                            role: 'menu',
                            className: this.classSet({'dropdownWrapper': true, 'hidden': !isDropdownOpen}),
                            children: this.createDropdownButtons(dropdownButtonHeight, dropdownRevert),
                            ref: 'dropdownWrapper',
                            style: dropdownStyle
                        })
                    ]
                }
            };
        },

        getMobileComponent() {
            const selectChild = santaComponents.utils.createReactElement('select', {
                value: this.props.currentLanguage.languageCode,
                onChange: ev => {
                    this.setCurrentLanguageCode(ev.target.value);
                },
                children: this.getOrderedLanguages().map(lang =>
                    santaComponents.utils.createReactElement('option', {
                        key: lang.languageCode,
                        value: lang.languageCode,
                        children: lang.name
                    })
                )
            });

            let className = this.classSet({'mobileLanguageSelector': true, 'newMobileLanguageSelector': true});
            const textStyle = this.getFontSize('itemFont', this.props.scale);
            let buttonChild = this.createButton(
                this.props.currentLanguage,
                {
                    isMobile: true,
                    className: this.classSet({'currentLanguage': true, 'hidden': false}),
                    arrowIconMode: this.props.compProp.hasArrow ? ArrowIconMode.VISIBLE : ArrowIconMode.HIDDEN,
                    style: textStyle,
                    onClick: () => {},
                    ref: 'currentLanguage',
                    iconType: this.getIconType()
                }
            );

            if (!this.props.compProp.iconSize) {
                className = this.classSet({'mobileLanguageSelector': true});
                let iconType = this.props.compTheme.style.properties.iconType;
                iconType = iconType === 'none' ? 'circle' : iconType;

                buttonChild = this.createButton(
                    this.props.currentLanguage,
                    {
                        isMobile: true,
                        className: this.classSet({'currentLanguage': true}),
                        arrowIconMode: ArrowIconMode.HIDDEN,
                        iconType,
                        onClick: () => {},
                        ref: 'currentLanguage'
                    }
                );
            }

            return {
                '': {
                    className,
                    children: [
                        buttonChild,
                        selectChild
                    ]
                }
            };
        },

        getIconType() {
            const {iconType} = this.props.compProp.iconSize ? this.props.compProp : this.props.compTheme.style.properties;
            return iconType;
        },

        getIconSize() {
            return this.props.compProp.iconSize || this.props.compTheme.style.properties.iconSize;
        },

        getViewByCurrentMode() {
            if (this.props.isMobileView && !this.props.compProp.iconSize) {
                return this.getMobileComponent();
            }
            if (this.props.compProp.displayMode === 'dropdown') {
                if (this.props.isMobileView) {
                    return this.getMobileComponent();
                }
                return this.getDropdownComponent();
            }
            return this.getHorizontalComponent();
        },

        getSkinProperties() {
            const hasActiveTranslationLanguages = _.size(this.props.languages) > 1;
            const shouldShowComponent = this.props.isPreviewMode || hasActiveTranslationLanguages;
            if (this.props.isEnabled && !_.isEmpty(this.props.currentLanguage) && shouldShowComponent) {
                return this.getViewByCurrentMode();
            }
            return {'': null};
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.LanguageSelector', languageSelector);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return languageSelector;
});
